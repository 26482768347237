import React from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useEmailAuth } from '@redsoftware/react-firebase';
import { ENTITIES, ROUTES } from './app-config';

const SignIn = React.lazy(() => import('./pages/SignIn'));
const Dashboard = React.lazy(() => import('./pages/Dashboard'));

function App() {
  const { authenticating, user } = useEmailAuth();

  if (authenticating) {
    return <Spinner />;
  } else if (user) {
    return (
      <React.Suspense fallback={<Spinner />}>
        <Switch>
          <Route exact path="/dashboard" component={Dashboard} />
          {Object.values(ENTITIES).map(({ path, page, routeProps }) => (
            <Route
              exact
              key={path}
              path={path}
              component={page}
              {...routeProps}
            />
          ))}
          {ROUTES.map(({ path, page, routeProps }) => (
            <Route
              exact
              key={path}
              path={path}
              component={page}
              {...routeProps}
            />
          ))}
          <Redirect to="/dashboard" />
        </Switch>
      </React.Suspense>
    );
  } else {
    return (
      <React.Suspense fallback={<Spinner />}>
        <Switch>
          <Route exact path="/sign-in" component={SignIn} />
          <Redirect to="/sign-in" />
        </Switch>
      </React.Suspense>
    );
  }
}

function Spinner(props) {
  return (
    <Dimmer active inverted>
      <Loader />
    </Dimmer>
  );
}

export default App;
