import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { EmailAuthProvider } from '@redsoftware/react-firebase';
import { BrowserRouter } from 'react-router-dom';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'react-toastify/dist/ReactToastify.min.css';
import 'semantic-ui-css/semantic.min.css';
import AppToaster from './components/AppToaster';
import * as Sentry from '@sentry/browser';
import ErrorBoundary from './components/ErrorBoundary';
import './index.css';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://bb0264375750486a92e3b71ae5481c11@sentry.io/1841036',
  });
}

const config = {
  apiKey: 'AIzaSyDQDzKLOONPZDRRJ08A988KVRcGkX2TmKw',
  authDomain: 'red-portal-42280.firebaseapp.com',
  databaseURL: 'https://red-portal-42280.firebaseio.com',
  projectId: 'red-portal-42280',
  storageBucket: 'red-portal-42280.appspot.com',
  messagingSenderId: '383484055638',
  appId: '1:383484055638:web:1686c3fc18218fb595ebf7',
};

firebase.initializeApp(config);

ReactDOM.render(
  <React.Fragment>
    <BrowserRouter>
      <EmailAuthProvider>
        <ErrorBoundary>
          <App />
        </ErrorBoundary>
      </EmailAuthProvider>
    </BrowserRouter>
    <AppToaster />
  </React.Fragment>,
  document.getElementById('root')
);
