import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';
import { Header, Icon, Segment, Button } from 'semantic-ui-react';
import Nav from './Nav';
import { getCurrentUser } from '@redsoftware/react-firebase';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { eventId: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo);
      scope.setUser('email', getCurrentUser().email);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <React.Fragment>
          <Nav
            breadcrumbs={[
              { path: '/dashboard', name: 'Dashboard' },
              {
                name: 'Error',
              },
            ]}
          />
          <Segment placeholder attached>
            <Header icon>
              <Icon name="times circle" />
              <p>Something went wrong. The error has been recorded.</p>
            </Header>
            <Button
              primary
              onClick={() =>
                Sentry.showReportDialog({ eventId: this.state.eventId })
              }
            >
              Report feedback
            </Button>
          </Segment>
        </React.Fragment>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
