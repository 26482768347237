import React from 'react';
import { Menu, Icon, Dropdown, Breadcrumb } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { auth } from 'firebase/app';
import { useEmailAuth } from '@redsoftware/react-firebase';
import { Helmet } from 'react-helmet';
import { APP_NAME } from '../app-config';

function Nav({ breadcrumbs = [], pageTitle, children, ...props }) {
  const { user } = useEmailAuth();
  let title = pageTitle ? `${pageTitle} | ${APP_NAME}` : APP_NAME;

  if (breadcrumbs.length && breadcrumbs[breadcrumbs.length - 1].name) {
    title = `${breadcrumbs[breadcrumbs.length - 1].name} | ${APP_NAME}`;
  }

  return (
    <Menu attached inverted style={{ background: '#24292e' }} {...props}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
      </Helmet>
      <Link to="/">
        <Menu.Item header>
          <Icon name="grid layout" />
          {APP_NAME}
        </Menu.Item>
      </Link>

      {Boolean(breadcrumbs.length) && (
        <Menu.Item
          css={`
            i.icon.divider {
              color: #fff;
            }

            .ui.breadcrumb {
              color: grey;
            }

            .ui.breadcrumb a {
              color: #fff;
            }
          `}
        >
          <Breadcrumb
            icon="right angle"
            sections={breadcrumbs.map((crumb, index) => ({
              key: index,
              content: crumb.path ? (
                <Link to={crumb.path}>{crumb.name}</Link>
              ) : (
                crumb.name
              ),
            }))}
          />
        </Menu.Item>
      )}

      {children}

      <Menu.Menu position="right">
        <Dropdown
          trigger={
            <>
              <Icon name="user" />
              {user.displayName || user.email}
            </>
          }
          className="link item"
        >
          <Dropdown.Menu>
            <Dropdown.Item
              as={() => (
                <Menu.Item
                  name="Sign out"
                  icon="sign-out"
                  onClick={e => auth().signOut()}
                />
              )}
            />
          </Dropdown.Menu>
        </Dropdown>
      </Menu.Menu>
    </Menu>
  );
}

export default Nav;
