import React from 'react';

export const APP_NAME = 'Rainbow';

export const ENTITIES = {
  expenses: {
    path: '/expenses',
    name: 'Expenses',
    icon: 'arrow down',
    dbPath: 'expenses',
    // tagKeys: ['firstName', 'lastName'],
    page: React.lazy(() => import('./pages/Expenses')),
    color: 'orange',
  },
  revenue: {
    path: '/revenue',
    name: 'Revenue',
    icon: 'arrow up',
    dbPath: 'revenue',
    // tagKeys: ['firstName', 'lastName'],
    page: React.lazy(() => import('./pages/Revenue')),
    color: 'green',
  },
  projects: {
    path: '/projects',
    name: 'Projects',
    icon: 'cube',
    dbPath: 'projects',
    // tagKeys: ['firstName', 'lastName'],
    page: React.lazy(() => import('./pages/Projects')),
    color: 'blue',
  },
  resources: {
    path: '/resources',
    name: 'Resources',
    icon: 'sun',
    dbPath: 'resources',
    // tagKeys: ['firstName', 'lastName'],
    page: React.lazy(() => import('./pages/Resources')),
    color: 'yellow',
  },
  invoices: {
    path: '/invoices',
    name: 'Invoices',
    icon: 'print',
    dbPath: 'invoices',
    color: 'purple',
  },
  configuration: {
    path: '/configuration',
    name: 'Configuration',
    icon: 'configure',
    dbPath: 'meta/configuration',
    page: React.lazy(() => import('./pages/Configuration')),
  },
};

export const DASHBOARD = [
  [
    ENTITIES.expenses,
    ENTITIES.revenue,
    ENTITIES.projects,
    ENTITIES.resources,
    ENTITIES.invoices,
    ENTITIES.configuration,
  ],
];

export const ROUTES = [];
